<template>
  <dx-util-popup
    ref="materialPurchaseAdjustmentPopupRef"
    width="400px"
    height="auto"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="materialPurchaseAdjustmentPopupShown"
    @hidden="materialPurchaseAdjustmentPopupHidden"
  >
    <div>
      <dx-util-form id="materialItem" ref="materialAdjustmentForm" :form-data="materialAdjustmentForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="materialAdjustmentForm">
        <dx-util-item data-field="adjustQuantity" editor-type="dxNumberBox" :label="{text: 'New Quantity'}">
          <dx-util-required-rule message="Quantity is required" />
        </dx-util-item>
      </dx-util-form>
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
import prepMaterialInventoryService from '@/http/requests/prep-material/prepMaterialInventoryService'

// import { Notify } from '@/@robustshell/utils'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    prepMaterialItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      popupTitle: '',
      materialAdjustmentForm: {
        adjustQuantity: null,
        prepMaterialId: null,
      },
    }
  },
  computed: {
    materialPurchaseAdjustmentPopup() {
      return this.$refs.materialPurchaseAdjustmentPopupRef.instance
    },
    quantityOptions() {
      return {
        format: '#,##0',
        min: 0,
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.materialAdjustmentForm.prepMaterialId = this.prepMaterialItem.id
        this.materialPurchaseAdjustmentPopup.show()
      },
    },
  },
  methods: {
    materialPurchaseAdjustmentPopupShown() {
      this.popupTitle = `Adjust the inventory of ${this.prepMaterialItem.title} `
    },
    materialPurchaseAdjustmentPopupHidden() {
    // Clear form when popup is closed
      this.materialAdjustmentForm.adjustQuantity = null
      this.materialAdjustmentForm.prepMaterialId = null
    },
    closePopup() {
      this.materialPurchaseAdjustmentPopup.hide()
    },
    async handleSubmit() {
      const result = this.$refs.materialAdjustmentForm.instance.validate()
      if (result.isValid) {
        await prepMaterialInventoryService.adjust(this.materialAdjustmentForm)
        this.$emit('emit-form-saved')
        this.closePopup()
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
